<template>
  <div :class="backgroundClasses" :id="id">
    <div class="section-content">
      <div class="section-title">Kontakta oss</div>
      <v-container fluid class="ma-0 pa-0">
        <v-row>
          <!--<v-col cols="12" sm="8">
            <form class="contact-form">
              <v-text-field
                v-model="name"
                label="Namn"
                required
                solo
                flat
                color="white"
              ></v-text-field>
              <v-text-field
                v-model="company"
                label="Företag"
                required
                solo
                flat
                color="white"
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="E-mail"
                required
                solo
                flat
                color="white"
              ></v-text-field>
              <v-textarea
                v-model="message"
                label="Meddelande"
                rows="5"
                required
                solo
                flat
                color="white"
              />
              <v-btn
                block
                elevation="0"
                class="dark-button mr-4"
                @click="submit"
              >
                Skicka
              </v-btn>
            </form>
          </v-col>-->
          <!-- <v-col cols="12" sm="4" :order="$vuetify.breakpoint.smAndUp ? 'last' : 'first'" :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : 'd-flex justify-start'"> -->
          <v-col cols="12" sm="12" class="d-flex justify-start">
            <div class="section-text">
              <b>Adress</b><br/>
              <p>iOrdning.org Sverige AB<br/>Box 98<br/>134 22 Gustavsberg</p>
              <p>info<span>@</span>iordning.<span style="width:0px;display:none">.</span>org</p>
              <p>Mattias Rotter, VD<br/>Tel 010 - 30 30 240</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
    background: {
      type: String,
      default: function () {
        return null;
      },
    },
  },

  data: () => ({
    name: "",
    company: "",
    email: "",
    message: "",
  }),

  computed: {
    backgroundClasses() {
      var clazz = { section: true };
      if (this.background) {
        clazz[this.background] = true;
      }
      return clazz;
    },
  },

  methods: {
    submit() {},
  },
};
</script>

<style lang="scss">
@import "@/assets/css/main.scss";

.contact-form label {
  color: rgba(#383950, 0.6) !important;
}
.contact-form input {
  color: #383950 !important;
}

</style>
